import axios, { AxiosError } from 'axios'
import { IResume } from './jobStore'
export class JobService {
  static baseUrl: string = process.env.REACT_APP_BASE_URL as string
  static baseUrlDoc: string = process.env.REACT_APP_BASE_DOC_URL as string

  static post: any = async (payload: IResume) => {
    return axios({
      method: 'post',
      url: this.baseUrl,
      headers: {
        'Content-Type': 'text/plain'
      },
      data: payload
    })
      .then((response: any) => response.data)
      .catch((error: AxiosError) => console.log(error))
  }

  static postDocument = async (payload: any) => {
    const formData = new FormData()
    formData.append('File', payload)

    return axios
      .post(this.baseUrlDoc, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response: any) => response.text())
      .then((result: any) => result)
      .catch((error: AxiosError) => error?.message)
  }
}
