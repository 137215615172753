/* eslint-disable @typescript-eslint/no-inferrable-types */
import { makeAutoObservable, runInAction } from "mobx"
import { TranslationService } from "./translationService"

class TranslationStore {
	success: boolean = false
	language: string= "en"
	translation!: any
	constructor() {
		makeAutoObservable(this)
	}
	read: any = async (lang: string) => {
		const res = await TranslationService.get(lang)
		runInAction(() => {
			this.success = res.status === 200
			if (res.status === 200) this.translation = res.data
		})
	}
	setLanguage: any = (lang: string) => runInAction(() => (this.language = lang))
}
export { TranslationStore }
