import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useScroll, useStores } from 'src/hooks'
import { Brand, Nav } from 'src/molecules'
import { Hamburger } from 'src/atoms'
import { SCROLL_YOFFSET_THRESHOLD } from 'src/constants/layout'
import styled from './header.module.scss'

export const Header: React.FC = observer(() => {
  const {
    defaultStore: {
      // nav: { navLinks },
      sideNav,
      setSideNav
    }
  } = useStores()
  const { yOffset } = useScroll()

  useEffect(() => {
    setSideNav({ enabled: true, show: false })
  }, [yOffset])

  return (
    <header
      id={styled.header}
      className={yOffset > SCROLL_YOFFSET_THRESHOLD ? styled.miniaturize : ''}
    >
      <div className={`row ${styled.row}`}>
        <div className={`one-third column ${styled.column}`} style={{ alignContent: 'flex-end' }}>
          <Brand />
        </div>

        <div className={`one-third column ${styled.column} ${styled.show}`}>
          {/* <Nav navLinks={navLinks} /> */}
        </div>

        <div className={`one-third column ${styled.column}`}>
          <div className={styled['hamburger-wrapper']}>
            <Hamburger
              lineStyle={yOffset > SCROLL_YOFFSET_THRESHOLD ? { backgroundColor: 'black' } : {}}
              onClick={() => setSideNav({ enabled: true, show: !sideNav.show })}
            />
          </div>
        </div>
      </div>

      {/* {sideNav && ( */}
      <Nav
        navLinks={['facility', 'about', 'services', 'careers', 'rentals', 'contact']}
        sideNav={sideNav}
      />
      {/* )} */}
    </header>
  )
})
