import React from "react"
import { useOnView } from "./useOnView"

export const useNav = (activeLinkId: string, setNavActiveLinkId: any) => {
	const ref: any = React.useRef()
	const isOnView: boolean = useOnView(ref)
	React.useEffect(() => {
		if (isOnView) setNavActiveLinkId(activeLinkId)
	}, [isOnView, setNavActiveLinkId, activeLinkId])
	return ref
}
