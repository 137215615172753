import { useEffect, useState } from 'react'
export const useOnView = (ref: any) => {
  const [isOnView, setIsOnView] = useState<boolean>(false)
  const intersectObserver = new IntersectionObserver(
    ([entryPoint]: any) => {
      setIsOnView(entryPoint.isIntersecting)
    },
    { threshold: 0.8 }
  )

  useEffect(() => {
    intersectObserver.observe(ref.current)
    return () => intersectObserver.disconnect()
  })
  return isOnView
}
