export const scrollToId: any = (
	navLink: string,
	heightOffset = 667
) => {
	const scrollToId = navLink.toLowerCase()
	const height = window.innerHeight
	return document.querySelector(`#${scrollToId}`)?.scrollIntoView({
		behavior: "smooth",
		block: `${height > heightOffset ? "end" : "start"}`
	})
}
