import styled from './hamburger.module.scss'
interface IBurger {
  className?: string
  onClick?: any
  lineStyle?: React.CSSProperties
  style?: React.CSSProperties
}
export const Hamburger = ({ className = '', lineStyle = {}, onClick, style = {} }: IBurger) => {
  return (
    <div className={`${styled.hamburger} ${className}`} style={style}>
      <label htmlFor={styled.icon} onClick={onClick}>
        <span style={lineStyle}></span>
        <span style={lineStyle}></span>
        <span style={lineStyle}></span>
      </label>
    </div>
  )
}
