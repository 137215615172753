import { DefaultStore } from "./default/defaultStore"
import { TranslationStore } from "./translation/translationStore"
import { JobStore } from "./job/jobStore"

class store {
	defaultStore: DefaultStore
	translationStore: TranslationStore
	jobStore: JobStore
	constructor() {
		this.defaultStore = new DefaultStore()
		this.translationStore = new TranslationStore()
		this.jobStore = new JobStore()
	}
}
export default store
