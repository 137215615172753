import { observer } from 'mobx-react'
import { Typography } from 'src/atoms'
import { useStores } from 'src/hooks'
import { scrollToId } from 'src/utils'
import styled from './nav.module.scss'

export interface ISideNav {
  enabled: boolean
  show: boolean
}
interface INav {
  navLinks: any
  collapse?: boolean
  sideNav?: ISideNav
}

const Nav = observer(({ navLinks = {}, sideNav = { enabled: false, show: false } }: INav) => {
  const {
    defaultStore: {
      nav: { activeLinkId, navMobileCollapse },
      setNavMobileCollapse
    },
    translationStore: { translation }
  }: any = useStores()

  const renderNavLink = (navLink: string, activeLinkId: string, onClick: any) => {
    const handleClick = () => {
      scrollToId(navLink)
      onClick()
    }

    return (
      <Typography
        key={navLink}
        text={translation?.menu[navLink]}
        className={activeLinkId === navLink.toLowerCase() ? styled['active-link'] : ''}
        onClick={handleClick}
      />
    )
  }

  const handleOnClick = () => {
    setNavMobileCollapse(!navMobileCollapse)
  }

  return (
    <nav
      id={sideNav.enabled ? styled.sideNav : styled.topNav}
      className={sideNav.show ? styled.show : ''}
    >
      {navLinks.map((navLink: string) => renderNavLink(navLink, activeLinkId, handleOnClick))}
    </nav>
  )
})
export { Nav }
