import { Typography } from 'src/atoms'
import styled from './button.module.scss'

interface IButton {
  icon?: any
  iconAlign?: 'left' | 'right'
  caption?: string
  variant?: string
  className?: string
  style?: React.CSSProperties
  onClick?: any
}

const Button = ({
  icon,
  iconAlign = 'left',
  caption,
  variant = 'p',
  className,
  style,
  onClick
}: IButton) => {
  return (
    <button className={`${styled.button ?? ''} ${className ?? ''}`} style={style} onClick={onClick}>
      {!icon ? null : iconAlign === 'left' ? <img src={icon} /> : null}
      <Typography text={caption} variant={variant} />
      {!icon ? null : iconAlign === 'right' ? <img src={icon} /> : null}
    </button>
  )
}
export { Button }
