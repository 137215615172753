import { action, makeObservable, observable } from 'mobx'
import { AxiosError } from 'axios'
import { JobService as service } from './jobService'

export interface IResume {
  id: string
  name: string
  email: string
  subject: string
  location: string
  resumeFile: any
  message: string
}
export interface IJob {
  id: string
  title: string
  location: string
  type: 'permanent' | 'temporary' | 'intern' | null
  datePosted: string
  desc: string
}
export class JobStore {
  job!: IJob
  jobs!: IJob[]
  success: boolean = false
  loading: boolean = false
  error?: AxiosError

  resumeId?: string

  stateSchema: any = {
    name: {
      value: '',
      error: ''
    },
    email: {
      value: '',
      error: ''
    },
    subject: {
      value: '',
      error: ''
    },
    location: {
      value: '',
      error: ''
    },
    resumeFile: {
      file: {},
      value: '',
      error: ''
    },
    message: {
      value: '',
      error: ''
    }
  }

  constructor() {
    makeObservable(this, {
      job: observable,
      jobs: observable,
      success: observable,
      loading: observable,
      error: observable,
      stateSchema: observable,
      resumeId: observable,
      create: action
    })
  }

  create: any = async (payload: any) => {
    this.loading = true
    service
      .post(payload)
      .then((result: any) => {
        this.success = result && true
        return result && true
      })
      .catch((error: AxiosError) => {
        this.error = error
        throw new AxiosError(error?.message)
      })
      .finally(() => {
        this.loading = false
      })
  }

  upload: any = async (payload: any) => {
    this.loading = true
    this.success = false
    service
      .postDocument(payload)
      .then((result: any) => {
        this.resumeId = result
        this.success = result && true
      })
      .catch((error: AxiosError) => {
        this.error = error
        throw new AxiosError(error?.message)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
