import { action, makeObservable, observable } from 'mobx'
import { ISideNav } from 'src/molecules/nav/Nav'
interface INav {
  navLinks: string[]
  activeLinkId: string | null
  navMobileCollapse: boolean
}
class DefaultStore {
  nav: INav = {
    navLinks: ['services', 'contact', 'careers'],
    activeLinkId: null,
    navMobileCollapse: false
  }

  sideNav!: ISideNav

  constructor() {
    makeObservable(this, {
      nav: observable,
      sideNav: observable,
      setNavActiveLinkId: action,
      setNavMobileCollapse: action,
      setSideNav: action,
      setNavLinks: action
    })
  }

  setNavActiveLinkId = (activeLinkId: string) => (this.nav = { ...this.nav, activeLinkId })

  setNavMobileCollapse = (navMobileCollapse: boolean) =>
    (this.nav = { ...this.nav, navMobileCollapse })

  setSideNav = (status: ISideNav) => (this.sideNav = status)

  setNavLinks = (links: []) => (this.nav.navLinks = links)
}
export { DefaultStore }
