import styled from './typography.module.scss'

interface ITypography {
  text?: string | null
  variant?: 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | string
  color?: any
  string?: string
  className?: string | null
  id?: string
  style?: React.CSSProperties
  onClick?: any
  props?: any
}

const Typography: any = ({
  text = '',
  variant = 'caption',
  color,
  className = '',
  id = '',
  style = {},
  onClick = null,
  props
}: ITypography) => {
  return (
    <p
      {...props}
      id={id}
      className={`${styled[variant]} ${className}`}
      color={color ?? ''}
      style={style}
      onClick={onClick}
    >
      {text}
    </p>
  )
}
export { Typography }
