import axios from "axios"
class TranslationService {
	static baseUrl: string = `${window.location.origin}/locale`
	static get: any = async (lang: string) => {
		return await axios
			.get(`${this.baseUrl}/${lang}/translation.json`)
			.then((res: any) => res)
			.catch((err: any) => err.res)
	}
}
export { TranslationService }
