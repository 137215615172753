import { lazy, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useStores } from './hooks'
import { Header } from './sections'

const Home = lazy(() => import('src/sections/home/Home'))
const Facility = lazy(() => import('src/sections/facility/Facility'))
const About = lazy(() => import('src/sections/about/About'))
const Service = lazy(() => import('src/sections/service/Service'))
const Join = lazy(() => import('src/sections/join/Join'))
const Rentals = lazy(() => import('src/sections/rentals/Rentals'))
const Contact = lazy(() => import('src/sections/contact/Contact'))

const App = () => {
  const { translationStore } = useStores()

  useEffect(() => {
    translationStore.read(document.documentElement.lang)
  }, [translationStore, translationStore.language])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="app">
      <Header />
      <Home />
      <Facility />
      <About />
      <Service />
      <Join />
      <Rentals />
      <Contact />

      {/* <Footer /> */}
    </div>
  )
}
export default observer(App)
