import { useState, useLayoutEffect } from 'react'

interface IDimensionParams {
  width: number
  height: number
}

export const useDimension = () => {
  const [dimension, setDimension] = useState<IDimensionParams>({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useLayoutEffect(() => {
    const updateSize = () => {
      setDimension({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [dimension])

  return dimension
}

interface IScroll {
  yOffset: number
  xOffset: number
}

export const useScroll = () => {
  const [scroll, setScroll] = useState<IScroll>({ yOffset: 0, xOffset: 0 })

  useLayoutEffect(() => {
    const updateScroll = () => {
      setScroll({ yOffset: window.scrollY, xOffset: window.scrollX })
    }

    window.addEventListener('scroll', updateScroll)

    return () => window.removeEventListener('scroll', updateScroll)
  }, [scroll])

  return scroll
}
