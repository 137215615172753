import { observer } from 'mobx-react'
import { logo } from 'src/assets'
import { Typography } from 'src/atoms'
import { scrollToId } from 'src/utils'
import styled from './brand.module.scss'
// import phoneIcon from '../../assets/phone-sign.png'

export const Brand = observer(() => {
  return (
    <div id={styled.brand}>
      <img
        src={logo}
        alt="brand"
        onClick={() => {
          scrollToId('home')
        }}
        className={styled.logo}
      />
      <a href="tel:250-960-2271" className={styled.phone}>
        {/* <img src={phoneIcon} width={16} height={16} style={{ maxWidth: 16, maxHeight: 16 }} /> */}
        <Typography text="☏ 250-960-2271" />
      </a>
    </div>
  )
  {
    /* <Hamburger
        checked={navMobileCollapse}
        className={styled.hamburger}
        onClick={() => setNavMobileCollapse(!navMobileCollapse)}
      /> */
  }
})
